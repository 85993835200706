import clsx from 'clsx';
import Image from 'next/image';
import { Button } from '../../../components/ui/button/Button';
import {
  Shimmer,
  toBase64,
} from '../../../components/utility/imageShimmer/ImageShimmer';
import type { ImageType } from '../../../types.d/storeTypes';
import styles from './CollectionIntroCard.module.scss';

export type CollectionIntroCardProps = {
  title?: string;
  description?: string;
  featuresTitle?: string;
  icons: ImageType[];
  theme?: 'light' | 'dark';
  buttonText?: string;
  buttonLink?: string;
};

export const CollectionIntroCard = ({
  title,
  description,
  featuresTitle,
  icons = [],
  theme = 'light',
  buttonText,
  buttonLink,
}: CollectionIntroCardProps) => {
  let themeClass = styles['collectionIntroCard--light'];
  if (theme === 'dark') {
    themeClass = styles['collectionIntroCard--dark'];
  }

  return (
    <div className={clsx(styles.collectionIntroCard, themeClass)}>
      <div>
        {title && (
          <h2
            className={clsx(
              'text-heading-h5-mobile text-heading-h5-desktop',
              styles.collectionIntroCard__title
            )}
          >
            {title}
          </h2>
        )}

        {description && (
          <p
            className={clsx(
              'text-heading-h6-mobile text-heading-h6-desktop',
              styles.collectionIntroCard__description
            )}
          >
            {description}
          </p>
        )}

        {buttonText && buttonLink && (
          <div className={styles.collectionIntroCard__button}>
            <Button
              href={buttonLink}
              modifiers={theme === 'dark' ? 'light' : 'dark'}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>

      {(featuresTitle || icons.length > 0) && (
        <div className={styles.collectionIntroCard__footer}>
          {featuresTitle && (
            <p
              className={clsx(
                'text-utility-utility-mobile text-utility-utility-desktop',
                styles.collectionIntroCard__featuresTitle
              )}
            >
              {featuresTitle}
            </p>
          )}

          <div className={styles.collectionIntroCard__features}>
            {icons.map((icon, index) => {
              return (
                <div key={index} className={styles.collectionIntroCard__icon}>
                  <Image
                    className={styles.collectionIntroCard__image}
                    src={icon.url}
                    alt={icon.title}
                    title={icon.title}
                    height={40}
                    width={40}
                    placeholder="blur"
                    blurDataURL={`data:image/svg+xml;base64,${toBase64(
                      Shimmer(icon.width, icon.height)
                    )}`}
                  />

                  <p
                    className={[
                      styles.collectionIntroCard__iconTitle,
                      'text-body-1',
                    ].join(' ')}
                  >
                    {icon.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
